@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Visa Dialect UI';
  src: url('./fonts/VisaDialectUI-Medium.eot');
  src: url('./fonts/VisaDialectUI-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/VisaDialectUI-Medium.woff') format('woff'),
    url('./fonts/VisaDialectUI-Medium.ttf') format('truetype'),
    url('./fonts/VisaDialectUI-Medium.svg#VisaDialectUI-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Visa Dialect UI';
  src: url('./fonts/VisaDialectUI-Regular.eot');
  src: url('./fonts/VisaDialectUI-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/VisaDialectUI-Regular.woff') format('woff'),
    url('./fonts/VisaDialectUI-Regular.ttf') format('truetype'),
    url('./fonts/VisaDialectUI-Regular.svg#VisaDialectUI-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Visa Dialect UI';
  src: url('./fonts/VisaDialectUI-Semibold.eot');
  src: url('./fonts/VisaDialectUI-Semibold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/VisaDialectUI-Semibold.woff') format('woff'),
    url('./fonts/VisaDialectUI-Semibold.ttf') format('truetype'),
    url('./fonts/VisaDialectUI-Semibold.svg#VisaDialectUI-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@layer base {
  :root {
    /* Font Family */
    --font-family-base: 'Visa Dialect UI', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

    /* Font Weight */
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semi-bold: 600;

    /* Font Size */
    --font-size-xxs: 0.625rem;
    --font-size-xs: 0.75rem;
    --font-size-sm: 0.9375rem;
    --font-size-md: 1rem;
    --font-size-lg: 1.125rem;
    --font-size-xl: 1.25rem;
    --font-size-xxl: 1.5rem;
    --font-size-xxxl: 2rem;

    /* Line Height */
    --line-height-default: 1;
    --line-height-xs: 1.15;
    --line-height-sm: 1.25;
    --line-height-md: 1.375;
    --line-height-lg: 1.5;
    --line-height-xl: 2;

    /* Brand Colors */
    --brand-color-primary-pure: #1434cb;
    --brand-color-primary-pure-rgb: 20, 52, 203;
    --brand-color-primary-light: #e7ebfa;
    --brand-color-primary-medium: #0e248e;
    --brand-color-primary-dark: #081551;
    --brand-color-secondary-pure: #fcc015;
    --brand-color-secondary-pure-rgb: 252, 192, 21;
    --brand-color-secondary-light: #fff9e8;
    --brand-color-secondary-medium: #b0860f;
    --brand-color-secondary-dark: #654d08;

    /* Highlight Colors */
    --highlight-1-color-pure: #2b94f5;
    --highlight-1-color-light: #e9f4fe;
    --highlight-2-color-pure: #d65168;
    --highlight-2-color-pure-rgb: 214, 81, 104;
    --highlight-2-color-light: #fbedef;
    --highlight-3-color-pure: #26cf44;

    /* Neutral Colors */
    --neutral-color-black-pure: #000000;
    --neutral-color-black-pure-rgb: 0, 0, 0;
    --neutral-color-gray-ultralight: #fafafa;
    --neutral-color-gray-light: #eeeeee;
    --neutral-color-gray-medium: #e3e3e3;
    --neutral-color-gray-dark: #9a9a9a;
    --neutral-color-gray-ultradark: #5c5c5c;
    --neutral-color-gray-ultradark-rgb: 92, 92, 92;
    --neutral-color-white-pure: #ffffff;
    --neutral-color-white-pure-rgb: 255, 255, 255;

    /* Feedback Colors */
    --feedback-color-success-pure: #10b981;
    --feedback-color-success-light: #ecfdf5;
    --feedback-color-success-medium: #047857;
    --feedback-color-success-dark: #064e3b;
    --feedback-color-warning-pure: #fbbf24;
    --feedback-color-warning-light: #fffbeb;
    --feedback-color-warning-medium: #f59e0b;
    --feedback-color-warning-dark: #92400e;
    --feedback-color-danger-pure: #ef4444;
    --feedback-color-danger-light: #fef2f2;
    --feedback-color-danger-medium: #b91c1c;
    --feedback-color-danger-dark: #7f1d1d;

    /* Border Radius */
    --border-radius-none: 0;
    --border-radius-xs: 0.25rem;
    --border-radius-sm: 0.5625rem;
    --border-radius-md: 1.375rem;
    --border-radius-lg: 1.5rem;
    --border-radius-circular: 50%;

    /* Border Width */
    --border-width-none: 0;
    --border-width-hairline: 1px;
    --border-width-thin: 2px;
    --border-width-thick: 3px;

    /* Opacity Levels */
    --opacity-level-semitransparent: 0.03;
    --opacity-level-ultralight: 0.05;
    --opacity-level-light: 0.25;
    --opacity-level-medium: 0.5;
    --opacity-level-semiopaque: 0.75;

    /* Dropshadow */
    --shadow-level-1: 0 0.19rem 1.25rem rgba(0, 0, 0, 0.15);
    --shadow-level-2: 0 0.19rem 1.25rem rgba(0, 0, 0, 0.3);

    /* Margins */
    --margin-quarck: 0.25rem;
    --margin-nano: 0.5rem;
    --margin-xxxs: 1rem;
    --margin-xxs: 1.5rem;
    --margin-xs: 1.875rem;
    --margin-sm: 2rem;
    --margin-md: 2.5rem;
    --margin-lg: 3rem;
    --margin-xl: 3.5rem;
    --margin-xxl: 4rem;
    --margin-xxxl: 5rem;
    --margin-huge: 7.5rem;
    --margin-giant: 10rem;

    /* Spacing Inset */
    --padding-inset-quarck: 0.25rem;
    --padding-inset-nano: 0.5rem;
    --padding-inset-xxs: 0.875rem;
    --padding-inset-xs: 1rem;
    --padding-inset-sm: 1.5rem;
    --padding-inset-md: 2rem;
    --padding-inset-lg: 2.5rem;
  }
}

body {
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-md);
  line-height: var(--line-height-md);
  color: var(--neutral-color-gray-ultradark);
}

/* Buttons */
.btn {
  width: 100%;
  font-weight: var(--font-weight-medium);
  padding: var(--padding-inset-nano) var(--padding-inset-sm);
  font-size: var(--font-size-lg);
  border-radius: var(--border-radius-none);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: var(--border-width-none);
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: var(--brand-color-primary-pure);
  color: var(--neutral-color-white-pure);
}

.btn-primary:hover {
  background-color: var(--brand-color-primary-medium);
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--brand-color-primary-pure-rgb), 0.25);
  background-color: var(--brand-color-primary-pure);
}

.btn-primary.disabled {
  background-color: rgba(var(--neutral-color-gray-ultradark-rgb), 0.7);
  color: var(--neutral-color-white-pure);
}

.btn-text {
  color: var(--brand-color-primary-pure);
  background-color: transparent;
  width: auto;
  padding: var(--padding-inset-nano);
}

.btn-text-small {
  font-size: var(--font-size-xs);
  color: var(--brand-color-primary-pure);
  background-color: transparent;
  width: auto;
  padding: var(--padding-inset-nano);
}

.btn-text-small i {
  font-size: var(--font-size-md);
  transform: translateY(1px);
}

.btn-text:hover {
  color: var(--brand-color-primary-medium);
}

.btn-text:focus,
.btn-text-small:focus {
  color: var(--brand-color-primary-dark);
}

.btn-text.disabled,
.btn-text-small.disabled {
  color: rgba(var(--neutral-color-gray-ultradark-rgb), 0.7);
}

.btn-text i {
  transform: translateY(2px);
}

.btn-icon {
  opacity: var(--opacity-level-medium);
  width: auto;
  padding: var(--padding-inset-nano);
  font-size: var(--font-size-xxl);
}

.btn-icon:hover {
  opacity: var(--opacity-level-semiopaque);
}

/* Texts */
h1 {
  font-size: var(--font-size-xxl);
  font-weight: var(--font-weight-semi-bold);
  color: var(--neutral-color-black-pure);
}

h2 {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semi-bold);
  color: var(--neutral-color-black-pure);
}

.small {
  font-size: var(--font-size-xs);
}

.msg {
  padding: var(--padding-inset-xxs);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  background-color: rgba(var(--neutral-color-gray-ultradark-rgb), 0.1);
  text-align: center;
}

.msg-error {
  background-color: rgba(var(--highlight-2-color-pure-rgb), 0.1);
  color: var(--highlight-2-color-pure);
}

a {
  color: var(--brand-color-primary-pure);
}

a:hover {
  color: var(--brand-color-primary-medium);
}

a:focus {
  outline-offset: 2px;
  outline-color: var(--brand-color-primary-medium);
}

/* Form Controls */
.form-label {
  font-size: var(--font-size-xs);
}

.form-label span {
  font-size: var(--font-size-sm);
  transform: translateY(2px);
  display: inline-block;
}

.form-group {
  margin-bottom: var(--margin-xxxs);
  position: relative;
}

.form-group .btn {
  position: absolute;
  right: 5px;
  top: 30px;
}

.form-group.code-number {
  display: flex;
  margin-top: var(--margin-xs);
}

.form-group.code-number input {
  width: 32px;
  padding: var(--padding-inset-nano) 0;
  text-align: center;
  font-size: var(--font-size-xl);
  display: inline-block;
  margin-right: var(--margin-quarck);
}

.form-group.code-number input:nth-child(4) {
  margin-left: var(--margin-nano);
}

.form-group-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: var(--margin-xxxs);
}

.form-control {
  border: 1px solid var(--neutral-color-gray-ultradark);
  min-width: 32px;
  padding: var(--padding-inset-nano) var(--padding-inset-xxs);
  line-height: var(--line-height-md);
  border-radius: var(--border-radius-xs);
  height: 40px;
  -moz-appearance: textfield;
}

.form-control::-webkit-outer-spin-button,
.form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control.error {
  border-color: var(--highlight-2-color-pure);
}

.form-control:focus {
  border: 2px solid var(--brand-color-primary-pure);
  box-shadow: none;
}

.form-select {
  appearance: none;
  border: 1px solid var(--neutral-color-gray-ultradark);
  position: relative;
  height: 40px;
}

.form-select:focus {
  border: 2px solid var(--brand-color-primary-pure);
  box-shadow: none;
}

.form-check {
  display: flex;
  align-items: center;
  margin-bottom: var(--margin-nano);
}

.form-check .form-check-input {
  width: 22px;
  height: 22px;
  border: 1px solid var(--neutral-color-gray-ultradark);
}

.form-check .form-check-input[type='checkbox'] {
  border-radius: var(--border-radius-xs);
}

.form-check .form-check-input:checked {
  background-color: var(--brand-color-primary-pure);
}

.form-check-label {
  margin-left: var(--margin-nano);
}

/* Stepper */
.stepper {
  display: flex;
  align-items: center;
  padding: var(--padding-inset-nano) var(--padding-inset-xs);
  background-color: rgba(var(--neutral-color-gray-ultradark-rgb), 0.05);
}

.stepper .stepper-circular {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  user-select: none;
  background-color: var(--brand-color-primary-pure);
  position: relative;
  flex-shrink: 0;
}

.stepper .stepper-circular span {
  z-index: 5;
  font-weight: var(--font-weight-semi-bold);
  color: var(--neutral-color-black-pure);
  font-size: var(--font-size-sm);
}

.stepper .stepper-circular:before {
  content: '';
  position: absolute;
  height: 70%;
  width: 70%;
  background-color: #ffffff;
  border-radius: 50%;
}

.stepper .stepper-text {
  margin-left: var(--margin-xxxs);
  display: flex;
  flex-direction: column;
}

.stepper .stepper-text--steps {
  font-size: var(--font-size-xxs);
  font-weight: var(--font-weight-semi-bold);
  text-transform: uppercase;
}

.stepper .stepper-text--title {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semi-bold);
  color: var(--neutral-color-black-pure);
  margin-bottom: var(--margin-quarck);
}

.stepper .stepper-text p {
  font-size: var(--font-size-xs);
}

.stepper.completed {
  background-color: rgba(var(--brand-color-primary-pure-rgb), 0.1);
}

.stepper.completed .stepper-circular span,
.stepper.completed .stepper-text p,
.stepper.completed .stepper-text--title,
.stepper.completed .stepper-text--steps {
  color: var(--brand-color-primary-pure);
}

/* Cards */
.voucher-card {
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  max-width: 355px;
  box-shadow: var(--shadow-level-1);
}

.voucher-card figure {
  overflow: hidden;
  height: 80px;
  position: relative;
  margin: 0;
}

.voucher-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.voucher-card .voucher-card--content {
  background-color: var(--neutral-color-white-pure);
  padding: var(--padding-inset-xs);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.voucher-card .voucher-card--discount {
  font-size: var(--font-size-xxl);
  font-weight: var(--font-weight-semi-bold);
  color: rgba(var(--neutral-color-black-pure-rgb), 0.25);
  display: block;
  margin-bottom: var(--margin-xxxs);
}

.voucher-card .btn {
  margin-bottom: var(--margin-nano);
}

.type-card {
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  box-shadow: var(--shadow-level-1);
  background-color: var(--neutral-color-white-pure);
  display: grid;
  place-items: center;
}

.type-card.active {
  border: 3px solid var(--brand-color-primary-pure);
}

.type-card--title {
  font-size: var(--font-size-xxs);
  margin-top: var(--margin-quarck);
}

/* Modals */
.modal-validation {
  box-shadow: var(--shadow-level-2);
  padding: var(--padding-inset-sm);
  display: grid;
  place-items: center;
  margin: 0 auto;
  max-width: 600px;
  border-radius: var(--border-radius-sm);
}

.modal-validation .modal-title {
  text-align: center;
  font-size: var(--font-size-xxl);
  color: var(--neutral-color-black-pure);
}

.modal-filters {
  box-shadow: var(--shadow-level-2);
  padding: var(--padding-inset-sm);
  margin: 0 auto;
  border-radius: var(--border-radius-sm);
}

.modal-filters .modal-filters--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--margin-xxs);
}

.modal-filters .modal-filters--header span {
  color: var(--neutral-color-black-pure);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
}

.modal-filters .modal-filters--content span {
  color: var(--neutral-color-black-pure);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semi-bold);
  margin-bottom: var(--margin-xxs);
  display: block;
}

/* Progress Bar */
.progress-bar {
  background-color: rgba(var(--neutral-color-gray-ultradark-rgb), 0.1);
  height: 34px;
  overflow: hidden;
  border-radius: var(--border-radius-md);
  width: 100%;
  position: relative;
  margin: var(--margin-xxs) 0;
}

.progress-bar div {
  background-color: var(--brand-color-secondary-pure);
  height: 100%;
  position: absolute;
  left: 0;
}

.progress-bar p {
  text-align: center;
}
.react-datepicker__day {
  margin: 4px 0 !important;
  width: 2rem;
}

.react-datepicker__day--in-selecting-range {
  background: #ffef99 !important;
  border-radius: 0 !important;
  color: #000000 !important;
}

.react-datepicker__day--in-range {
  background: #ffef99 !important;
  border-radius: 0 !important;
  color: #000000 !important;
}

.react-datepicker__day--range-end {
  background: #fcc015 !important;
  border-top-right-radius: 25% !important;
  border-bottom-right-radius: 25% !important;
  color: #000000 !important;
  font-weight: 600 !important;
}

.react-datepicker__day--selected {
  background: #fcc015 !important;
  border-top-left-radius: 25% !important;
  border-bottom-left-radius: 25% !important;
  color: #000000 !important;
  font-weight: 600 !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #ffef99 !important;
  color: #000000 !important;
}

.react-datepicker__day-name {
  text-transform: uppercase;
  color: #96918c;
  font-size: 11px;
}

.react-datepicker__header {
  background-color: #ffffff;
  border-bottom: none;
}

.react-datepicker {
  font-family: var(--font-family-base);
}

@media only screen and (max-width: 768px) {
  .react-datepicker {
    border: none !important;
  }

  .react-datepicker__day {
    padding: 4px 0 !important;
    width: 2.5rem;
    font-size: 16px;
    line-height: 30px;
  }

  .react-datepicker__day-name {
    text-transform: uppercase;
    color: #96918c;
    font-size: 11px;
    margin: 7px;
  }

  .react-datepicker__day--keyboard-selected {
    background: #fcc015 !important;
    color: #000000 !important;
  }
}
