.bgtext {
  position: relative;
}

.bgtext:after {
  content: attr(data-customattr);
  position: absolute;
  top: 5px;
  right: 0px;
  z-index: 0;
  text-overflow: clip;
  white-space: nowrap;
}
